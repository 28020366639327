/* eslint-disable jsx-a11y/alt-text */
import './style.scss'

import React, { Component } from 'react'
import classNames from 'classnames'

type ButtonType = 'primary' | 'secondary' | 'link' | 'plain' | 'danger'

type Props = {
  className?: string,
  icon?: string,
  type?: ButtonType,
  submit?: Boolean,
  children?: any,
  onClick: Function,
  innerRef?: Function,
  disabled?: boolean,
  style?: Object
}

export default class Button extends Component<Props> {
  render() {
    const {
      className,
      // icon,
      type,
      submit,
      children,
      onClick,
      innerRef,
      disabled,
      style
    } = this.props

    return (
      <button
        type={submit ? 'submit' : 'button'}
        className={classNames('Button', className, type)}
        onClick={disabled ? undefined : onClick}
        ref={innerRef}
        disabled={disabled}
        style={style}>
        {/* {icon ? <img src={icons[icon]} /> : null} */}
        {children ? <span>{children}</span> : null}
      </button>
    )
  }
}
