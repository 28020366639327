import './style.scss'

import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import Button from '../Button/Button'

const div = document.createElement('div')
div.className = 'Confirm'

export default function confirm({
  confirmButtonText = 'Yes',
  confirmButtonType = 'danger',
  message = 'Are you sure?'
} = {}) {
  return new Promise(resolve => {
    const cancel = () => {
      cleanup()
      resolve(false)
    }

    const confirm = () => {
      cleanup()
      resolve(true)
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        cancel()
      }
    }

    const cleanup = () => {
      document.removeEventListener('keydown', onKeyDown)
      unmountComponentAtNode(div)
      document.body.removeChild(div)
    }

    document.body.appendChild(div)
    document.addEventListener('keydown', onKeyDown)

    render(
      <div className="content">
        <div className="header">Confirm</div>
        <div className="body">{message}</div>
        <div className="footer">
          <Button type="plain" onClick={cancel}>
            Cancel
          </Button>
          <Button type={confirmButtonType} onClick={confirm}>
            {confirmButtonText}
          </Button>
        </div>
      </div>,
      div
    )
  })
}
