import React from 'react'
import { AppBar as BaseAppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import logo from '../../images/logo.png'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
})

const AppBar = props => {
  const classes = useStyles()
  return (
    <BaseAppBar {...props}>
      <img
        src={logo}
        alt="treehouse-logo"
        style={{ height: '40px', position: 'relative', zIndex: -1, left: -10 }}
      />
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <span className={classes.spacer} />
    </BaseAppBar>
  )
}

export default AppBar
