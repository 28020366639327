import './style.scss'

import React from 'react'
import { DateInput } from 'react-admin'

const DateRangeInput = () => {
  return (
    <div className="DateRangeInput">
      <div>
        <DateInput source="due_date__date__gte" label="From" />
      </div>
      <div>
        <DateInput source="due_date__date__lte" label="To" />
      </div>
    </div>
  )
}

export default DateRangeInput
