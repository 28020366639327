import React, { createElement } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, getResources } from 'react-admin'
import { withRouter } from 'react-router-dom'
import Home from '@material-ui/icons/Home'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)

  return (
    <div>
      <MenuItemLink to="/" primaryText="Home" leftIcon={<Home />} sidebarIsOpen={open} />
      {resources
        .filter(r => r.hasList)
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || _.capitalize(resource.name)
            }
            leftIcon={resource.icon ? createElement(resource.icon) : null}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      <MenuItemLink
        to="/admin/"
        target="_blank"
        primaryText="Admin"
        leftIcon={<SupervisorAccountIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
