import React from 'react'
import _ from 'lodash'
import { Form } from 'react-final-form'
import { useMediaQuery } from '@material-ui/core'
import { DateInput } from 'react-admin-date-inputs'
import { DateRangeInput } from '../components/shared'
import {
  ChipField,
  Create,
  Datagrid,
  DateField,
  // DateInput,
  Edit,
  Filter,
  List,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleList,
  SingleFieldList,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useQueryWithStore
} from 'react-admin'

const statusChoices = [
  { id: 'OPEN', name: 'Open' },
  { id: 'CLOSED', name: 'Closed' }
]

const TaskFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <ReferenceArrayInput label="Assignees" source="assignees" reference="users">
      <SelectArrayInput optionText="first_name" />
    </ReferenceArrayInput>
    <DateRangeInput label="Due Date" source="due_date" />
    <SelectInput label="Status" source="status" choices={statusChoices} />
    <NumberInput label="Priority" source="priority" />
    <TextInput label="Title" source="title" />
  </Filter>
)

const TaskStatusField = (props: Object) => {
  const { record } = props
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const onSubmit = async (status: String) => {
    const payload = {
      id: record.id,
      data: { status }
    }

    await dataProvider.update('tasks', payload)
    notify('ra.notification.updated', 'info', { smart_count: 1 })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ status: record.status }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} onClick={e => e.stopPropagation()}>
            <SelectInput
              source="status"
              choices={statusChoices}
              onChange={e => onSubmit(e.target.value)}
            />
          </form>
        )
      }}
    />
  )
}

export const TaskList = props => {
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'users',
    payload: { pagination: { page: 1, perPage: 1000 }, sort: { field: 'id', order: 'ASC' } }
  })

  const getAssignees = record => {
    if (!data) {
      return ''
    }

    const users = record.assignees.map(id => data.find(u => u.id === id).first_name)
    return users.join(', ')
  }

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'))

  return isSmall ? (
    <List {...props} filters={<TaskFilter />}>
      <SimpleList
        primaryText={record => `[${_.capitalize(record.status)}] ${record.title}`}
        secondaryText={getAssignees}
        tertiaryText={record =>
          record.due_date ? `Due ${new Date(record.due_date).toLocaleDateString()}` : ''
        }
      />
    </List>
  ) : (
    <List {...props} filters={<TaskFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="description" />
        <TaskStatusField />
        <DateField source="due_date" />
        <ReferenceArrayField label="Assignees" reference="users" source="assignees" link={false}>
          <SingleFieldList>
            <ChipField source="first_name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="priority" />
        <ReferenceField label="Project" source="project" reference="projects" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_date" />
        <DateField source="modified_date" />
        <ReferenceField label="Owner" source="owner" reference="users" link={false}>
          <TextField source="first_name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

export const TaskCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" multiline />
      <SelectInput source="status" choices={statusChoices} />
      <DateInput source="due_date" label="Due Date" options={{ format: 'M/d/yyyy' }} />
      <ReferenceArrayInput label="Assignees" source="assignees" reference="users">
        <SelectArrayInput optionText="first_name" />
      </ReferenceArrayInput>
      <ReferenceInput label="Project" source="project" reference="projects">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="priority" />
    </SimpleForm>
  </Create>
)

export const TaskEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" multiline />
      <SelectInput source="status" choices={statusChoices} />
      <DateInput source="due_date" label="Due Date" options={{ format: 'M/d/yyyy' }} />
      <ReferenceArrayInput label="Assignees" source="assignees" reference="users">
        <SelectArrayInput optionText="first_name" />
      </ReferenceArrayInput>
      <ReferenceInput label="Project" source="project" reference="projects">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="priority" />
      <ReferenceInput label="Owner" source="owner" reference="users">
        <SelectInput optionText="first_name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
