import './style.scss'

import React, { Component, Fragment } from 'react'
import classNames from 'classnames'

type Props = {
  current: string,
  steps: string[]
}

type State = {}

export default class DiscreteProgessBar extends Component<Props, State> {
  state = {}

  render() {
    const { steps, current } = this.props

    return (
      <div className="DiscreteProgessBar">
        {steps.map((step, i) => (
          <Fragment key={i}>
            {i > 0 ? <div className="bar" /> : null}
            <div
              key={step}
              className={classNames('step', { active: step === current })}
            >
              {step}
            </div>
          </Fragment>
        ))}
      </div>
    )
  }
}
