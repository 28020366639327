import axios from 'axios'

let _user
let _csrf

export default {
  login: async ({ username, password }) => {
    const res = await axios.post('/login/', { email: username, password })
    _user = res.data.user
    _csrf = res.data.csrf
    axios.defaults.headers['X-CSRFToken'] = _csrf
  },
  logout: async params => {
    await axios.post('/logout/')
    _user = null
  },
  checkAuth: async params => {
    if (!_csrf) {
      const res = await axios.get('/session/')
      _user = res.data.user
      _csrf = res.data.csrf
      axios.defaults.headers['X-CSRFToken'] = _csrf
    }

    if (!_user) {
      throw new Error('Not authenticated')
    }
  },
  checkError: async error => {
    // no op
  },
  getPermissions: async params => {
    // no op
  },
  get user() {
    return _user
  },
  get csrf() {
    return _csrf
  }
}
