export function getFullName(customer) {
  const { first_name: first, last_name: last, email } = customer
  if (first && last) {
    return `${first} ${last}`
  }

  return email
}

export function getBaseUrl() {
  if (process.env.NODE_ENV !== 'development') {
    return ''
  }

  const { origin } = window.location
  return `${origin.replace(':8001', ':2222')}`
}

export function getImageUrl(url: string) {
  if (process.env.NODE_ENV !== 'development') {
    return url
  }

  const baseUrl = getBaseUrl()

  return `${baseUrl}${url}`
}

/**
 * This is the valid email regular expression from the HTML Spec.
 * See https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
 * for more info.
 */
const VALID_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export function isValidEmail(email: string) {
  return VALID_EMAIL_REGEX.test(email)
}

export function areFieldsEmpty(obj: Object, fields: String[]) {
  if (!obj) {
    return true
  }

  for (const field of fields) {
    const value = obj[field]
    if (!value || value.trim().length === 0) {
      return true
    }
  }

  return false
}
