import './style.scss'

import React, { Component } from 'react'
import classNames from 'classnames'
import Button from '../Button/Button'

type Props = {
  label: string,
  children: any,
  defaultIsOpen?: Boolean
}

type State = {
  isOpen: boolean
}

export default class CollapsibleSection extends Component<Props, State> {
  state = {
    isOpen: this.props.defaultIsOpen
  }

  static defaultProps = {
    defaultIsOpen: false
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { label, children } = this.props
    const { isOpen } = this.state

    return (
      <div className={classNames('CollapsibleSection', { open: isOpen })}>
        <div className="title" onClick={this.toggle}>
          <Button icon="triangle" />
          <div>{label}</div>
        </div>
        {isOpen ? <div className="body">{children}</div> : null}
      </div>
    )
  }
}
