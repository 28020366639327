/* eslint-disable jsx-a11y/alt-text */
import './style.scss'

import React, { Component } from 'react'
import { Portal } from 'react-portal'
import classNames from 'classnames'

type Props = {
  className: String,
  title: String,
  buttons: any,
  children: any,
  onClose: Function
}

type State = {
  atTop: Boolean,
  atBottom: Boolean
}

export default class Modal extends Component<Props, State> {
  modalBodyNode: HTMLDivElement

  state = {
    atTop: true,
    atBottom: true
  }

  onBackgroundClick = (e: Event) => {
    if (e.target === e.currentTarget) {
      this.props.onClose()
    }
  }

  onScroll = (e: Event) => {
    const node: HTMLElement = e ? e.currentTarget : this.modalBodyNode
    if (node.scrollTop === 0) {
      if (!this.state.atTop) {
        this.setState({ atTop: true })
      }
    } else {
      if (this.state.atTop) {
        this.setState({ atTop: false })
      }
    }

    if (node.scrollTop >= node.scrollHeight - node.clientHeight) {
      if (!this.state.atBottom) {
        this.setState({ atBottom: true })
      }
    } else {
      if (this.state.atBottom) {
        this.setState({ atBottom: false })
      }
    }
  }

  onRef = node => {
    this.modalBodyNode = node
    if (node) {
      this.onScroll(null)
    }
  }

  render() {
    const { className, title, buttons, children } = this.props
    const { atTop, atBottom } = this.state

    return (
      <Portal>
        <div className={classNames('Modal', className)} onClick={this.onBackgroundClick}>
          <div className="modal-wrapper">
            <div className="modal-header">{title}</div>
            <div
              className={classNames('modal-body', { 'at-top': atTop, 'at-bottom': atBottom })}
              onScroll={this.onScroll}
              ref={this.onRef}>
              {children}
            </div>
            <div className="modal-footer">{buttons}</div>
          </div>
        </div>
      </Portal>
    )
  }
}
