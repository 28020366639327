import 'react-big-calendar/lib/css/react-big-calendar.css'
import './overrides.scss'
import './style.scss'

import React, { Component } from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import classNames from 'classnames'
import moment from 'moment'
// import Event from '../../../models/Event'
import { ScreenWithClose } from '../Screen/Screen'

const localizer = momentLocalizer(moment)

const TIME_FORMAT = 'h:mmA'

const calendarComponents = {
  event: function BasicEventInfo({ event }) {
    const e = event ? { ...event } : {}
    const customer = e.customer || {}
    const { first_name, last_name } = customer
    return (
      <span className="BasicEventInfo">
        <span className="time">
          {moment(e.start)
            .format(TIME_FORMAT)
            .replace(':00', '')}
          -
          {moment(e.end)
            .format(TIME_FORMAT)
            .replace(':00', '')}
        </span>
        <strong className="title">
          {first_name && last_name
            ? `${first_name} ${last_name}`
            : e.title
            ? e.title
            : e.type !== 'WASH'
            ? e.type
            : 'Select'}
        </strong>
      </span>
    )
  }
}

type Props = {
  events: Object[],
  renderForm?: Function,
  onSelectSlot?: Function,
  onSelectEvent?: Function,
  onSave?: Function,
  readonly?: Boolean,
  views?: any
}

type State = {
  dialog: 'NEW_EVENT' | 'EDIT_EVENT',
  event: Event
}

export default class Calendar extends Component<Props, State> {
  state = {
    dialog: null,
    event: null
  }

  onSelectSlot = async (slotInfo: Object) => {
    this.setState({
      dialog: 'NEW_EVENT',
      event: {
        type: 'WASH',
        title: '',
        description: '',
        date: moment(slotInfo.start).format('YYYY-MM-DD'),
        startTime: '08:00',
        endTime: '10:00',

        address: '',
        employee_id: '',
        customer_id: '',
        allDay: false,
        send_email: false
      }
    })
  }

  onSelectEvent = async (event: Object) => {
    this.setState({ dialog: 'EDIT_EVENT', event })
  }

  hideDialog = () => {
    this.setState({ dialog: null })
  }

  onSave = () => {
    this.hideDialog()
  }

  renderForm = event => {
    return 'renderForm'
  }

  render() {
    const {
      events,
      readonly,
      views,
      onSelectEvent = this.onSelectEvent,
      onSelectSlot = this.onSelectSlot,
      renderForm = this.renderForm
    } = this.props

    const { dialog, event } = this.state

    const props = {
      onSelectEvent
    }

    if (!readonly) {
      props.onSelectSlot = onSelectSlot
    }

    return (
      <div className={classNames('Calendar', { readonly })}>
        <BigCalendar
          localizer={localizer}
          events={events}
          components={calendarComponents}
          startAccessor={e => new Date(e.start)}
          endAccessor={e => new Date(e.end)}
          views={views}
          selectable
          popup
          {...props}
        />
        {dialog === 'NEW_EVENT' || dialog === 'EDIT_EVENT' ? (
          <ScreenWithClose onClose={this.hideDialog}>
            {React.cloneElement(renderForm(event), { onSave: this.onSave })}
          </ScreenWithClose>
        ) : null}
      </div>
    )
  }
}
