import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Admin, Resource, Title, fetchUtils } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ListIcon from '@material-ui/icons/List'
import drfProvider from './dataProvider'
import ReactDOM from 'react-dom'
import axios from 'axios'
import debug from 'debug'
import authProvider from './authProvider'
import history from './history'
import { TaskList, TaskCreate, TaskEdit } from './resources/tasks'
import Layout from './components/layout/Layout'

const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:2222/api' : '/api'

window._debug = debug

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/api'
} else {
  const { protocol, hostname } = window.location
  const baseURL = `${protocol}//${hostname}:2222/api`

  axios.defaults.baseURL = baseURL
  axios.defaults.withCredentials = true
  window._axios = axios
}

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers()
  }

  options.headers.set('X-CSRFToken', authProvider.csrf)
  options.credentials = 'include'
  return fetchUtils.fetchJson(url, options)
}

const dataProvider = drfProvider(API_URL, httpClient)

ReactDOM.render(
  <BrowserRouter>
    <Admin
      history={history}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={Layout}
      dashboard={() => (
        <Card>
          <Title title="Treehouse Task Manager" />
          <CardContent>hi dawn</CardContent>
        </Card>
      )}>
      <Resource name="tasks" list={TaskList} create={TaskCreate} edit={TaskEdit} icon={ListIcon} />
      <Resource name="users" />
      <Resource name="projects" />
    </Admin>
  </BrowserRouter>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
