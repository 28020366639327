import './style.scss'

import React, { Component, Fragment } from 'react'
// import axios from 'axios'
// import _debug from 'debug'
import Button from '../Button/Button'
import { Modal, Form, Field } from '..'
// import { handleError } from '../../../util/error-util'
import { isValidEmail } from '../../../util/string-util'
// import { initSession } from '../../../store/actions/app'

// const debug = _debug('sfh:components:CreateAccountModal')

type Props = {
  onCancel: Function,
  onSuccess: Function
}

type State = {
  name: String,
  email: String,
  password: String
}

export default class CreateAccountModal extends Component<Props, State> {
  state = {
    name: '',
    email: '',
    password: ''
  }

  // onSubmit = async () => {
  //   try {
  //     const { onSuccess } = this.props
  //     const name = this.state.name.trim()
  //     const email = this.state.email.trim()
  //     const password = this.state.password
  //     const res = await axios.post('/account/create', { name, email, password })
  //     const { user, csrf } = res.data
  //     initSession(user, undefined, csrf)
  //     debug('[onSubmit] res: ', res)
  //     onSuccess()
  //   } catch (err) {
  //     handleError(err)
  //   }
  // }

  render() {
    const { onCancel } = this.props
    const { name, email, password } = this.state

    return (
      <Modal
        className="CreateAccountModal"
        title="Create Account"
        onClose={onCancel}
        buttons={
          <Fragment>
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!name.trim() || !isValidEmail(email) || !password.trim()}
              onClick={this.onSubmit}>
              Create Account
            </Button>
          </Fragment>
        }>
        <Form onSubmit={this.onSubmit}>
          <Field
            type="text"
            label="Name"
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <Field
            type="email"
            label="Email"
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
          />
          <Field
            type="password"
            label="Password"
            value={password}
            onChange={e => this.setState({ password: e.target.value })}
          />
        </Form>
      </Modal>
    )
  }
}
