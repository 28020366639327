import './style.scss'

import React, { Component } from 'react'
import classNames from 'classnames'

type Props = {
  value: any,
  type: string,
  name: string,
  label: string,
  placeholder?: string,
  autoComplete?: string,
  autoCapitalize?: string,
  autoFocus?: boolean,
  onChange: Function
}

type State = {
  type: String
}

const NUMBER_TYPES = ['num', 'number', 'currency', 'percent']

export default class Input extends Component<Props, State> {
  state = {
    type: null
  }

  render() {
    const { label, className, ...props } = this.props
    const { type } = this.state

    if (NUMBER_TYPES.includes(props.type)) {
      props.onBlur = () => {
        const { value, onChange } = props
        if (typeof value === 'string') {
          const parsedValue = parseFloat(value)
          if (!isNaN(parsedValue)) {
            onChange({ target: { value: parsedValue } })
          }
        }
      }
    }

    return (
      <div
        className={classNames('Input', className, {
          'has-value': Boolean(props.value)
        })}>
        {props.type === 'currency' ? <span className="dollarsign">$</span> : null}
        {props.type === 'percent' ? <span className="percentsign">%</span> : null}
        {props.type === 'textarea' ? (
          <textarea {...props} type={undefined} />
        ) : (
          <input {...props} type={type || props.type} />
        )}
        <label>{label}</label>
        {props.type === 'password' ? (
          <span className="toggle" onClick={() => this.setState({ type: type ? null : 'text' })}>
            {type === 'text' ? 'Hide' : 'Show'}
          </span>
        ) : null}
      </div>
    )
  }
}
